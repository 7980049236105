
import { MainListComponent} from '../SmallComponents/SmallListComponent'
 
interface JobElement {
    title: string;
    highlightText?: string;   
    subTitle1: string;
    subTitle2?: string;
    subTitle3?: string;
}

interface Job {
    JobId: string;
    JobName: string;
    JobDescription: string;
    JobWFStatus: string;
}

interface JobList {
    value: string;
    label: string;
    list: JobElement[];
    showAvatars: boolean;
}

const CustomerJobsComponent = () => {
    return (
        <MainListComponent openAsDialog={false} defaultFilterValue="active" type="customerjob" />
    )
}

export { CustomerJobsComponent }