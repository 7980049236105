
import { PageTemplate } from './PageTemplate'
import { CustomerJobsComponent } from '../components/Customer/CustomerJobsComponent'
import { CustomerJobComponent } from '../components/Customer/CustomerJobComponent'

const ShowCustomerJobsPage = () => {
    return (
        <PageTemplate
            type="customer" headerPage="jobpage" headerElement="header"
            leftPaneComponent={CustomerJobsComponent()} mainComponent={CustomerJobComponent()} buttonComponent={null} >
        </PageTemplate>
    );
}
export { ShowCustomerJobsPage }