import { Suspense, createResource, createSignal, Show, For, splitProps } from "solid-js";
import { createStore, } from "solid-js/store";
import { useParams } from "@solidjs/router";
import { Avatar, Typography, Button, IconButton, Stack, Card, Alert, Grid, Divider, Box } from "@suid/material";
import { SocialMediasElement, AllocationElement, DurationElement, RatesElement, ContactsElement, CompetenciesElements, TextElement, ValueElement, AddressElement } from '../SmallComponents/SmallTextElements';

import { Tabs, Tab } from '../SmallComponents/SmallTabs'; 
import { showWfCustomerToDoIncommingMatchesItems, AvatarWithWfButtons, showWfCustomerToDoCompletedItems, showWfCustomerJobsOpenItems, showWfCustomerToDoProposalItems, showWfCustomerToDoContractItems, showWfCustomerToDoConfirmedInterviewItems, showWfCustomerToDoNotConfirmedInterviewItems, showWfCustomerJobsNotStaffedItems } from '../SmallComponents/SmallAvatarWithWfButtons'
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { AddIcon, PersonAddIcon, MoreVertIcon, EditIcon } from '../icons';

import { formatDateByCountry } from "../../../utils/datetimeflag"

import { ContractRates } from '../MediumComponents/MediumContractRatesNew'
import { FieldWorkModel } from "../SmallComponents/SmallFieldWorkModel"; 
import { CustomerJobMenu } from '../Customer/CustomerJobMenu'
 
import { ShowHeading } from '../SmallComponents/SmallHeader'
import { CompetenceList } from '../SmallComponents/SmallChipsCompetence'
import { CandidateProgressQuestionmark, CandidateProgressCircle } from '../SmallComponents/SmallCandidateProgress'
import { useNavigate } from "@solidjs/router";
import { isExtraSmall } from "../../../utils/sizeUtil"

import { getText, GetTextUI } from '../SmallComponents/Translate'

import { CustomerJobContactsDataElementsType, CustomerCVSOMEDataElementsType, CustomerJobFetchType, CustomerJobRoleDataElementsType } from "../../../types/customerJobDataTypes"
import { CandidateCompetenciesFetchType } from "../../../types/candidateCVDataTypes"

const apiUrl = import.meta.env.VITE_APP_API_URL;


const customerJobsMatchesNotStartedWFFilter = ["WFA0"]
const customerJobsIncommingMatchesWFFilter = ["WFA1", "WFA2a"]
const customerJobsPlanInterviewsWFFilter = ["WFA2b"]
const customerJobsConfirmedInterviewsWFFilter = ["WFA2"]
const customerJobsProposalWFFilter = ["WFA2c"]
const customerJobsContractWFFilter = ["WFA2d"]
const customerJobsInWorkWFFilter = ["WFA3", "WFA4"]
const customerJobsCompletedWFFilter = ["WFA5"]
const customerJobsNotSelectedWFFilter = ["WFA6a", "WFA6b", "WFA6c", "WFA6d", "WFA6e", "WFA6f"]

type CardProps = {
    iconName: string
    header: string;
    avatarList?: CustomerJobRoleDataElementsType; //any[];
    filterValues: string[];
    showWfItemsFunction: any;
    defaultOpen: boolean;
    dummyText?: string;
};


const fetchJob = async (jobId: string | null): Promise<CustomerJobFetchType> => {
    const response = await fetch(`${apiUrl}/job/customerjob/${jobId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Job response was not ok.');
    }
    const data = await response.json();
    return data;
}
const fetchCompetencies = async (candidateId: string | null): Promise<CandidateCompetenciesFetchType> => {
    const response = await fetch(`${apiUrl}/candidate/competencies/${candidateId}`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('Competencies response was not ok');
    }
    const data = await response.json();
    return data;
}


const CustomerJobComponent = () => {

    const params = useParams();
    const fetchId = params.id ? params.id : "1";

    const [competencies] = createResource<CandidateCompetenciesFetchType>(() => fetchCompetencies(fetchId));
    const [job] = createResource<CustomerJobFetchType>(() => fetchJob(fetchId));

    const navigate = useNavigate();
    const handleNavigateRoute = (name: string) => {
        navigate(name);
    }

    const [jobRoleFilterId, setJobRoleFilterId] = createSignal<string>("")
    const [jobRoleFilterName, setJobRoleFilterName] = createSignal<string>("")

    const [activeTab, setActiveTab] = createSignal<number>(0)

    const txtRoles = getText("default", "roles")
    const txtRates = getText("jobpage", "rates")
    const txtMatchingNotStarted = getText("jobpage", "openroles")
    const txtIncommingMatches = getText("todopage", "ongoingmatches")
    const txtPlanInterviews = getText("todopage", "notacceptedinterviews")
    const txtConfirmInterviews = getText("todopage", "upcomminginterviews")
    const txtProposals = getText("default", "proposals")
    const txtContracts = getText("default", "contracts")

    const txtInWork = getText("default", "inwork")
    const txtCompleted = getText("default", "workcomplete")
    const txtNotSelected = getText("jobpage", "notselected")
   
    const txtJobInformation = getText("jobpage", "jobinformation")
    const txtDetails = getText("jobpage", "details")
    const txtAboutus = getText("jobpage", "aboutus")
    const txtWhatoffer = getText("jobpage", "whatweoffer")
    const txtTechcompetence = getText("jobpage", "techcompetence")
    const txtPersoncompetence = getText("jobpage", "personcompetence")

    const txtWorkmodel = getText("jobpage", "workmodel")
    
    const txtLanguages = getText("default", "languages")

    const RenderCard = (props: CardProps) => {
        const [local] = splitProps(props, ['dummyText'])

        //<For each={props?.avatarList?.filter(filterItem => (filterItem.wfStatus === props.filterValue && (jobRoleFilterId() ? filterItem.jobRoleId === jobRoleFilterId() : true)))}>

        return (
            <>
                <Show when={props?.avatarList?.filter(filterItem => (props.filterValues.includes(filterItem.wfStatus) && (jobRoleFilterId() ? filterItem.jobRoleId === jobRoleFilterId() : true))).length}>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
                        <CardWithMinimize iconName={props.iconName} header={props.header + " (" + props?.avatarList?.filter(filterItem => (props.filterValues.includes(filterItem.wfStatus) && (jobRoleFilterId() ? filterItem.jobRoleId === jobRoleFilterId() : true))).length + ")"} type="stack" defaultOpen={props.defaultOpen}>

                            <For each={props?.avatarList?.filter(filterItem => (props.filterValues.includes(filterItem.wfStatus) && (jobRoleFilterId() ? filterItem.jobRoleId === jobRoleFilterId() : true)))}>
                                {(item) => (
                                    <AvatarWithWfButtons
                                        avatarSize="medium"
                                        showWfItems={props.showWfItemsFunction}
                                        candidateId={item.candidateId}
                                        jobRoleId={item.jobRoleId}
                                        jobAssignmentId={item.jobAssignmentId}
                                        avatarName={item.firstName ? item.firstName + " " + item.lastName : item.jobRoleName}
                                        jobName={item.jobName}
                                        locationCountry={item.jobCountry}
                                        locationCity={item.jobCity}
                                        jobStartDate={formatDateByCountry(item.jobStartDate)}
                                        jobEndDate={formatDateByCountry(item.jobEndDate)}
                                        workOnsite={item.workOnsite}
                                        workRemote={item.workRemote}
                                        onSiteText={item.onSiteText}
                                        currency={item.currency}
                                        rateFlat={item.maxFlatRate}
                                        rateOnsite={item.maxOnSiteRate}
                                        rateRemote={item.maxRemoteRate}
                                        wfStatus={item.wfStatus}
                                        role={item.jobRoleName}
                                        src={item.src}
                                    />
                                )}
                            </For>
                        </CardWithMinimize>
                    </Grid>
                </Show>
            </>
        );
    };

    const [state, setState] = createStore({
        workModel: 'Remote',
        remoteWork: '3',
        rateModel: 'Simple',
        expenseModel: 'AllInclusive',
        fromDate: '2023-05-01',
        toDate: '2023-05-15',
        calendarDays: 0,
        allocation: '8',
        allocationOnSite: '0',
        allocationRemote: '0',
        allocationUnits: 'Hours' || 'Days',
        allocationUnitsPr: 'Day' || 'Week' || 'Month',
        currency: 'EUR',
        ratePer: 'Hour' || 'Day' || 'Week' || 'Month',
        rateValue: 0,
        remoteRateValue: 0,
        onSiteRateValue: 0,
        expenseValue: 0,
        remoteExpenseValue: 0,
        onSiteExpenseValue: 0,
        totalHours: 0,
        totalOnSiteHours: 0,
        totalRemoteHours: 0,

        sumRates: '0.00',
        sumExpenses: '0.00',
        sumTotal: '0.00',
    });
    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };

    const handleInputChange = (key: string, value: string) => {
        /*
        try {
            const setValue = props[key as keyof CustomerJobEditProps];

            if (typeof setValue === "function") {
                const setValue = props[key as keyof CustomerJobEditProps] as ((newValue: string) => void) | undefined;
                if (setValue) {
                    setValue(value);

                }
            } else {
                const setValue = props[key as keyof CustomerJobEditProps] as ((newValue: string) => void) | undefined;
                if (setValue) {
                    setValue(value);
                }

                //  throw new Error(`Tried to set prop "${key}", but it's not a function. Got: ${setValue}`);
            }
        } catch (error) {

        }
        */
    };

    function getProgressValue(): number {
        const values = [20, 40, 60, 80, 100];
        const randomIndex = Math.floor(Math.random() * values.length);
        return values[randomIndex];
    }

    const handleClickRoleButton = (roleId: string, roleName: string) => {
        setJobRoleFilterId(jobRoleFilterId() === roleId ? "" : roleId)
        setJobRoleFilterName(jobRoleFilterName() === roleName ? "" : roleName)
        setLevel(level() === roleId ? "" : roleId)
    }
    const [level, setLevel] = createSignal("0");

    const callJobRoute = (roleId: string) => {
        handleNavigateRoute("/customerjobroleview/" + roleId)
    }

    type WordListProps = {
        words: string;
    };

    const WordList = (props: WordListProps) => {
        const modifiedWords = props.words + " a b c";

        const words = modifiedWords.split(" ");
        return (
            <>
                {words.map((word, index) => (
                    <Show when={index < 3}>
                        <span >
                            <Show when={words.length - 3 - index > 0}>
                                {word}
                            </Show>
                            {index < words.length - 1 && <br />}
                        </span>
                    </Show>
                ))}
            </>
        );
    }

    const [isEntering, setIsEntering] = createSignal(false);


    return (
        <>
            <Card sx={{ margin: 1 }}>
                {/* First row of the job section show the local menubar, tags and other action buttons */}

                <CustomerJobMenu showActivities={true} />

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>

                    <For each={job()?.job}>
                        {(item) => (
                            <>
                                <Stack direction="column">

                                    <CardWithMinimize header={txtJobInformation() as string} type="list" defaultOpen={((job()?.jobRoles?.length ?? 0) > 0) ? false : true}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Stack direction="row" margin={1} spacing={1}>
                                                    <Stack direction="column" spacing={0.5} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                                        <Avatar sx={{ margin: 1, display: 'flex', width: 160, height: 160 }} variant="rounded" src={item.src} />
                                                    </Stack>

                                                    <Stack spacing={0.5}>
                                                        <Stack direction="row">
                                                            <Typography variant="h4">{item.jobName}</Typography>
                                                            <IconButton size="small" onClick={() => handleNavigateRoute("/customerjobedit")}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Stack>


                                                        {/* Job location */}

                                                        <AddressElement page="contact" label="address" data={""} country={item?.jobCountry} postalCode={""} city={item?.jobCity} street={""} />

                                                        <SocialMediasElement page="jobpage" label="social" SOMEs={job()?.jobSOMEs as CustomerCVSOMEDataElementsType} />


                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Stack direction="column" margin={1} spacing={1}>

                                                    <TextElement page="jobpage" label="jobdescription" data={item.jobDescription} />

                                                    <ContactsElement page="jobpage" label="contacts" contacts={job()?.contacts as CustomerJobContactsDataElementsType} />

                                                </Stack>
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                <Divider />
                                                <Stack margin={1}>
                                                    <Tabs activeTab={activeTab()} setActiveTab={setActiveTab}>

                                                        <Tab>{txtLanguages()}</Tab>
                                                        <Tab>{txtDetails()}</Tab>
                                                        <Tab>{txtWorkmodel()}</Tab>
                                                        <Tab>{txtRates()}</Tab>

                                                    </Tabs>
                                                </Stack>
                                                <Show
                                                    when={activeTab() === 0}
                                                >

                                                    <Show when={competencies()?.languages !== undefined && competencies()?.languages !== null}>

                                                        <CompetenceList showAddButton={false} type="language" direction="row" header={txtLanguages() as string} options={competencies()?.languages} />

                                                    </Show>
                                                </Show>
                                                <Show
                                                    when={activeTab() === 1}
                                                >
                                                    <CardWithMinimize header={txtDetails() as string} type="list" defaultOpen={true}>

                                                        <Stack margin={1} spacing={1}>
                                                            <Show when={item?.jobAboutUs}>
                                                                <Typography >{txtAboutus()}</Typography>
                                                                <Typography color="text.secondary">   </Typography>
                                                            </Show>
                                                            <Show when={item?.jobWhatWeOffer}>
                                                                <Typography >{txtWhatoffer()}</Typography>
                                                                <Typography color="text.secondary">    </Typography>
                                                            </Show>
                                                            <Show when={item?.jobTechnicalCompetencies}>
                                                                <Typography >{txtTechcompetence()}</Typography>
                                                                <Typography color="text.secondary">   </Typography>
                                                            </Show>
                                                            <Show when={item?.jobPersonelCompetencies}>
                                                                <Typography >{txtPersoncompetence()}</Typography>
                                                                <Typography color="text.secondary">    </Typography>
                                                            </Show>
                                                        </Stack>
                                                    </CardWithMinimize>
                                                </Show>
                                                <Show
                                                    when={activeTab() === 2}
                                                >
                                                    <CardWithMinimize header={txtWorkmodel() as string} type="list" defaultOpen={true}>
                                                        <FieldWorkModel
                                                            workModel={state.workModel}
                                                            remoteWork={state.remoteWork}
                                                            setWorkModel={value => handleInputChange("setWorkModel", value)}
                                                            setRemoteWork={value => handleInputChange("setRemoteWork", value)}
                                                        />
                                                    </CardWithMinimize>
                                                </Show>
                                                <Show
                                                    when={activeTab() === 3}
                                                >
                                                    <CardWithMinimize header={txtRates() as string} type="list" defaultOpen={true}>


                                                        {/* Contract Rates - both simple and advanced*/}
                                                        <ContractRates
                                                            calendarDays={state.calendarDays}
                                                            showAllocation={false}
                                                            numberOfHours={String(state.totalHours)}
                                                            setHours={(newValue) => handleChange('totalHours', newValue)}
                                                            numberOfOnSiteHours={state.allocationOnSite}
                                                            numberOfRemoteHours={state.allocationRemote}
                                                            setOnSiteAllocation={(newValue) => handleChange('allocationOnSite', newValue)}
                                                            setRemoteAllocation={(newValue) => handleChange('allocationRemote', newValue)}

                                                            allocation={state.allocation}
                                                            setAllocation={(newValue) => handleChange('allocation', newValue)}
                                                            allocationUnits={state.allocationUnits}
                                                            setAllocationUnits={(newValue) => handleChange('allocationUnits', newValue)}
                                                            allocationUnitsPr={state.allocationUnitsPr}
                                                            setAllocationUnitsPr={(newValue) => handleChange('allocationUnitsPr', newValue)}

                                                            rateModel={state.rateModel}
                                                            setRateModel={(newValue) => handleChange('rateModel', newValue)}
                                                            expenseModel={state.expenseModel}
                                                            setExpenseModel={(newValue) => handleChange('expenseModel', newValue)}

                                                            currency={state.currency}
                                                            setCurrency={(newValue) => handleChange('currency', newValue)}

                                                            ratePer={state.ratePer}
                                                            setRatePer={(newValue) => handleChange('ratePer', newValue)}

                                                            rateValue={state.rateValue.toString()}
                                                            remoteRateValue={state.remoteRateValue.toString()}
                                                            onSiteRateValue={state.onSiteRateValue.toString()}
                                                            setRateValue={(newValue) => handleChange('rateValue', newValue)}
                                                            setRemoteRateValue={(newValue) => handleChange('remoteRateValue', newValue)}
                                                            setOnSiteRateValue={(newValue) => handleChange('onSiteRateValue', newValue)}

                                                            expenseValue={state.expenseValue.toString()}
                                                            remoteExpenseValue={state.remoteExpenseValue.toString()}
                                                            onSiteExpenseValue={state.onSiteExpenseValue.toString()}
                                                            setExpenseValue={(newValue) => handleChange('expenseValue', newValue)}
                                                            setRemoteExpenseValue={(newValue) => handleChange('remoteExpenseValue', newValue)}
                                                            setOnSiteExpenseValue={(newValue) => handleChange('onSiteExpenseValue', newValue)}

                                                            totalHours={state.totalHours}
                                                            totalRemoteHours={state.totalRemoteHours}
                                                            totalOnSiteHours={state.totalOnSiteHours}

                                                            sumRates={state.sumRates}
                                                            sumExpenses={state.sumExpenses}
                                                            sumTotal={state.sumTotal}
                                                            setSumRates={(newValue) => handleChange('sumRates', newValue)}
                                                            setSumExpenses={(newValue) => handleChange('sumExpenses', newValue)}
                                                            setSumTotal={(newValue) => handleChange('sumTotal', newValue)}
                                                        />

                                                    </CardWithMinimize>
                                                </Show>

                                            </Grid>

                                        </Grid>
                                    </CardWithMinimize>


                                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                                        <Show when={jobRoleFilterName() === ""}
                                            fallback={
                                                <>
                                                    <GetTextUI formName={"jobpage"} label={"jobintotal"} /> {job()?.jobRoles?.length} <GetTextUI formName={"jobpage"} label={"jobintotalroles"} /> <strong>{item.jobName}</strong> <GetTextUI formName={"jobpage"} label={"jobcurrentview"} /> <strong>{jobRoleFilterName()}</strong> <GetTextUI formName={"jobpage"} label={"jobintotalall"} />
                                                </>
                                            }
                                        >
                                            {job()?.jobRoles?.length} <GetTextUI formName={"jobpage"} label={"jobintotalroles"} /> <strong>{item.jobName}</strong> <GetTextUI formName={"jobpage"} label={"jobintotalall2"} />
                                        </Show>
                                    </Alert>

                                    {/* Roles defined for job */}
                                    <Stack >
                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            <ShowHeading size="small" >{txtRoles()}</ShowHeading>
                                            <CandidateProgressQuestionmark />
                                        </Stack>

                                        <Stack sx={{ margin: 2, display: 'flex' }} direction="row" alignItems="center" spacing={1}
                                        >
                                            <Stack direction="column" alignItems="center">
                                                <Button ><PersonAddIcon sx={{ width: 56, height: 56 }} /></Button>
                                                <Typography align="center" fontSize="80%" variant="body2" color="text.secondary">
                                                    <GetTextUI formName={"jobpage"} label={"addjobrole"} />
                                                </Typography>
                                            </Stack>
                                            <For each={job()?.jobRoles}>
                                                {(item) => (
                                                    <>
                                                        <Show when={level() === item.jobRoleId}
                                                            fallback={
                                                                <>
                                                                    <Card onMouseEnter={() => setIsEntering(true)} onMouseLeave={() => setIsEntering(false)} onClick={() => handleClickRoleButton(item.jobRoleId, item.jobRoleName)}
                                                                        sx={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column',
                                                                            verticalAlign: 'top',
                                                                            position: 'relative',
                                                                            height: '100%',
                                                                            minWidth: '85px',
                                                                            overflow: 'hidden',
                                                                            cursor: 'pointer',
                                                                            backgroundColor: level() === item.jobRoleId ? "var(--find-bg-color-attention-card)" : "var( --find-bg-color-card)"
                                                                        }}
                                                                    >
                                                                        <Stack margin={1} direction="column"  >
                                                                            <CandidateProgressCircle color={level() === item.jobRoleId ? "success" : "primary"} avatar={item.src} badgeNumber={Number(item.jobRoleNumberAssignments)} width={68} defaultProgress={Number(item.jobRoleNumberAssignments) === 0 ? 0 : getProgressValue()} />
                                                                            <Typography align="center" fontSize="80%" variant="body2" color={"text.secondary"}>
                                                                                <WordList words={item.jobRoleName} />
                                                                            </Typography>
                                                                            <Show when={isEntering() && level() === item.jobRoleId}>
                                                                                <Box
                                                                                    position="absolute"
                                                                                    right={1}
                                                                                    bottom={1}

                                                                                >
                                                                                    <IconButton onClick={() => callJobRoute(item.jobRoleId)} size="small" color="success">
                                                                                        <MoreVertIcon />
                                                                                    </IconButton>
                                                                                </Box>
                                                                            </Show>
                                                                        </Stack>

                                                                    </Card>
                                                                </>
                                                            }
                                                        >
                                                            <Card onClick={() => handleClickRoleButton(item.jobRoleId, item.jobRoleName)}
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    verticalAlign: 'top',
                                                                    position: 'relative',
                                                                    height: '100%',
                                                                    minWidth: '80px',
                                                                    overflow: 'hidden',
                                                                    cursor: 'pointer',
                                                                    borderWidth: '4px',
                                                                    borderStyle: 'solid',
                                                                }}
                                                            >
                                                                <Stack margin={1} direction="column"  >
                                                                    <CandidateProgressCircle color={level() === item.jobRoleId ? "success" : "primary"} avatar={item.src} badgeNumber={Number(item.jobRoleNumberAssignments)} width={68} defaultProgress={Number(item.jobRoleNumberAssignments) === 0 ? 0 : getProgressValue()} />
                                                                    <Typography align="center" fontSize="80%" variant="body2" color={"text.secondary"}>
                                                                        <WordList words={item.jobRoleName} />
                                                                    </Typography>
                                                                    <Box
                                                                        position="absolute"
                                                                        right={1}
                                                                        bottom={1}
                                                                    >
                                                                        <IconButton size="small" onClick={() => callJobRoute(item.jobRoleId)} color="success">
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                </Stack>

                                                            </Card>
                                                        </Show>
                                                    </>
                                                )}
                                            </For>
                                        </Stack>
                                    </Stack>

                                    {/* Show a card for each group */}
                                    <Show when={job()?.jobRoles !== undefined}>
                                        <Grid container >
                                            <RenderCard dummyText="d" iconName="matching" filterValues={customerJobsMatchesNotStartedWFFilter} header={txtMatchingNotStarted() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={job()?.jobAssignments} showWfItemsFunction={showWfCustomerJobsOpenItems} />
                                            <RenderCard dummyText="d" iconName="matching" filterValues={customerJobsIncommingMatchesWFFilter} header={txtIncommingMatches() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={job()?.jobAssignments} showWfItemsFunction={showWfCustomerToDoIncommingMatchesItems} />
                                            <RenderCard dummyText="d" iconName="interview" filterValues={customerJobsPlanInterviewsWFFilter} header={txtPlanInterviews() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={job()?.jobAssignments} showWfItemsFunction={showWfCustomerToDoNotConfirmedInterviewItems} />
                                            <RenderCard dummyText="d" iconName="interview" filterValues={customerJobsConfirmedInterviewsWFFilter} header={txtConfirmInterviews() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={job()?.jobAssignments} showWfItemsFunction={showWfCustomerToDoConfirmedInterviewItems} />
                                            <RenderCard dummyText="d" iconName="proposal" filterValues={customerJobsProposalWFFilter} header={txtProposals() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={job()?.jobAssignments} showWfItemsFunction={showWfCustomerToDoProposalItems} />
                                            <RenderCard dummyText="d" iconName="contract" filterValues={customerJobsContractWFFilter} header={txtContracts() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={job()?.jobAssignments} showWfItemsFunction={showWfCustomerToDoContractItems} />
                                            <RenderCard dummyText="d" iconName="" filterValues={customerJobsInWorkWFFilter} header={txtInWork() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={job()?.jobAssignments} showWfItemsFunction={showWfCustomerToDoCompletedItems} />
                                            <RenderCard dummyText="d" iconName="" filterValues={customerJobsCompletedWFFilter} header={txtCompleted() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={job()?.jobAssignments} showWfItemsFunction={showWfCustomerToDoCompletedItems} />
                                            <RenderCard dummyText="d" iconName="" filterValues={customerJobsNotSelectedWFFilter} header={txtNotSelected() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={job()?.jobAssignments} showWfItemsFunction={showWfCustomerJobsNotStaffedItems} />
                                        </Grid>
                                    </Show>

                                </Stack>
                            </>
                        )}
                    </For>

                    <Show when={job()?.job === undefined || job()?.job === null || job()?.job?.length === 0}>
                        <Divider />
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            <GetTextUI formName={"jobpage"} label={"jobnotfound"} />
                            <br /><br />
                            <Button fullWidth variant="contained" size="small"  >
                                <AddIcon />
                                <GetTextUI formName={"jobpage"} label={"addjob"} />
                            </Button>
                        </Alert>

                    </Show>
                    <br />
                    <br />

                </Suspense>

            </Card>
        </>
    );
}

export { CustomerJobComponent }